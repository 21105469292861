import { useState, type FC, type ReactNode } from "react";
import propTypes from "prop-types";
import CheckIcon from "@untitled-ui/icons-react/build/esm/Check";
import type { SxProps } from "@mui/system/styleFunctionSx";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import type { Theme } from "@mui/material/styles/createTheme";
import { useNavigate } from "react-router";

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { ButtonLoader } from "src/components/button-loader";

interface Feature {
  value: string;
  isBold?: boolean;
}

interface PricingPlanProps {
  cta: string;
  currency: string;
  description: string;
  features: Feature[];
  icon: ReactNode;
  name: string;
  popular?: boolean;
  price: string;
  period: string;
  plan: string;
  isPopular: boolean;
  companyId?: string | null;
  currentPlan?: string | null;
  sx?: SxProps<Theme>;
}

export const PricingPlan: FC<PricingPlanProps> = (props) => {
  const {
    cta,
    currency,
    description,
    features,
    icon,
    name,
    price,
    sx,
    period,
    plan,
    isPopular,
    companyId,
    currentPlan,
    ...other
  } = props;

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckout = () => {
    if (localStorage.getItem("temp_token")) {
      navigate(`/checkout?plan=${plan}`);
    } else {
      window.location.replace(
        `${
          process.env.REACT_APP_OPERATINGPRO_BASE_URL ||
          "https://app.operatingpro.com"
        }/auth/register-company?plan=${plan}`
      );
    }
  };

  const handleSubscriptionUpdate = async () => {
    if (!companyId || !currentPlan || currentPlan === plan) {
      return;
    }
    setIsLoading(true)
    const data = {
      planType: plan,
      amount: Number(price.replace(",", "")),
      companyId: companyId,
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/payment/update-subscription`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // "Authorization": `Bearer ${window.localStorage.getItem("temp_token")}`
        },
        body: JSON.stringify(data),
      }
    );

    if (response.ok) {
      setIsLoading(false);
      window.location.replace(
        `${process.env.REACT_APP_OPERATINGPRO_BASE_URL}/dashboard/account?tab=billing`
      );
    }
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        ...sx,
      }}
      {...other}
    >
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              height: 52,
              width: 52,
            }}
          >
            {icon}
          </Box>
          {isPopular && (
            <Box>
              {/* <Button variant="contained" sx={{}}>Popular</Button> */}
              <Box
                sx={{
                  background: "#2970FF",
                  color: "#fff",
                  padding: "4px 8px",
                  borderRadius: "8px",
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <AutoAwesomeIcon />
                <span style={{ padding: "0 8px 0 6px" }}>Popular</span>
              </Box>
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h4">
            {currency}
            {price}
          </Typography>
          <Typography
            color="text.secondary"
            sx={{
              alignSelf: "flex-end",
              ml: 1,
            }}
            variant="subtitle2"
          >
            / {period}
          </Typography>
        </Box>
        <Typography sx={{ mt: 2 }} variant="h6">
          {name}
        </Typography>
        <Typography color="text.secondary" sx={{ mt: 2 }} variant="body2">
          {description}
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          p: 3,
        }}
      >
        <Stack spacing={2} sx={{ flexGrow: 1 }}>
          {features.map((feature) => (
            <Stack
              alignItems="center"
              direction="row"
              spacing={1}
              key={feature.value}
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <SvgIcon color="success">
                <CheckIcon />
              </SvgIcon>
              <Typography sx={{ fontWeight: 500 }} variant="body2">
                <span style={feature.isBold ? { fontWeight: "bold" } : {}}>
                  {feature.value}
                </span>
              </Typography>
            </Stack>
          ))}
        </Stack>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 6,
          }}
        >
          <Button
            fullWidth
            variant={isPopular ? "contained" : "outlined"}
            onClick={
              companyId && currentPlan
                ? handleSubscriptionUpdate
                : handleCheckout
            }
          >
            {cta}
            {isLoading && (
              <>
                <span style={{ paddingLeft: "12px" }}></span>
                <ButtonLoader loading={isLoading} contained={!isPopular}/>
              </>
            )}
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

PricingPlan.propTypes = {
  cta: propTypes.string.isRequired,
  currency: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  features: propTypes.array.isRequired,
  icon: propTypes.any.isRequired,
  name: propTypes.string.isRequired,
  popular: propTypes.bool,
  price: propTypes.string.isRequired,
  sx: propTypes.object,
};
