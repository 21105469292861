import { CircularProgress, Fade } from '@mui/material'
import React, { FC } from 'react'

interface ButtonLoaderProps {
    loading: boolean;
    contained?: boolean;
    size?: number;
}

export const ButtonLoader: FC<ButtonLoaderProps> = (props) => {
    const { loading, contained, size = 24 } = props;
  return (
    <Fade
        in={loading}
        style={contained ? {} : {
            color: "white"
        }}
        unmountOnExit
    >
        <CircularProgress color='inherit' size={size}/>
    </Fade>
  )
}
