import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";

import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { PricingPlan } from "src/sections/pricing/pricing-plan";
import { PricingPlanIcon } from "src/sections/pricing/pricing-plan-icon";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

const getCTAText = (plan: string , companyId?: string | null, currentPlan?: string | null) => {
  if (!companyId || !currentPlan) { return 'Start 7-day Free Trial'}
  else if (currentPlan === plan) { return 'In Use'}
  return 'Upgrade'
}

const Page = () => {
  usePageView();

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (queryParams.has("token")) {
      window.localStorage.setItem("temp_token", location.search.split("=")[1]);
      queryParams.delete("token");
      navigate("/pricing");
    } 
  }, []);

  const companyId = queryParams.get("company-id");
  const currentPlan = queryParams.get("current-plan");

  return (
    <>
      <Seo title="Pricing" />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "dark" ? "neutral.800" : "neutral.50",
            pb: "120px",
            pt: "184px",
          }}
        >
          <Container maxWidth="lg">
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                mb: 4,
              }}
            >
              <Typography variant="h3">
                Start today. Empower your team!
              </Typography>
              <Typography color="text.secondary" sx={{ my: 2 }} variant="body1">
                Join CEOs, COOs, and operations teams in using Operating PRO to
                power modern businesses.
              </Typography>
              {/* <Stack
                alignItems="center"
                direction="row"
                spacing={1}
              >
                <Switch checked />
                <Typography variant="body1">
                  Yearly Payment
                </Typography>
                <Chip
                  color="primary"
                  label="25% OFF"
                  size="small"
                />
              </Stack> */}
            </Box>
            <Grid
              container
              spacing={4}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid xs={12} md={4}>
                <PricingPlan
                  companyId={companyId}
                  currentPlan={currentPlan}
                  cta={getCTAText("business", companyId, currentPlan)}
                  currency="$"
                  description="To familiarize yourself with our tools."
                  features={[
                    {
                      value: "Up to 3 Group Entities",
                    },
                    {
                      value: "Up to 15 of your Team",
                    },
                    {
                      value: "Group Company Structure",
                    },
                    {
                      value: "Group Team Org Chart",
                    },
                    {
                      value: "Group Transactions",
                    },
                    {
                      value: "Intercompany Agreements",
                    },
                    {
                      value: "Transfer Pricing Policy",
                    },
                    {
                      value: "Group Tax Policy",
                    },
                    {
                      value: "Regulatory Calendar",
                    },
                    {
                      value: "Document File Manager",
                    },
                    {
                      value: "Business Customer Support",
                    },
                  ]}
                  icon={<PricingPlanIcon name="standard" />}
                  name="Business PRO"
                  price="1,900"
                  period="year"
                  plan="business"
                  isPopular = {false}
                  sx={{
                    height: "100%",
                    maxWidth: 460,
                    mx: "auto",
                  }}
                />
              </Grid>
              <Grid xs={12} md={4}>
                <PricingPlan
                  companyId={companyId}
                  currentPlan={currentPlan}
                  cta={getCTAText("enterprise", companyId, currentPlan)}
                  currency="$"
                  description="To familiarize yourself with our tools."
                  features={[
                    {
                      value: "Everything in Business PRO",
                      isBold: true,
                    },
                    {
                      value: "Up to 10 Group Entities",
                    },
                    {
                      value: "Up to 50 of your Team",
                    },
                    {
                      value: "Transfer Pricing Master File",
                    },
                    {
                      value: "Benchmarking Studies",
                    },
                    {
                      value: "Industry Reports",
                    },
                    {
                      value: "Enhanced Customer Support",
                    },
                  ]}
                  icon={<PricingPlanIcon name="business" />}
                  name="Enterprise PRO"
                  price="4,900"
                  period="year"
                  plan="enterprise"
                  isPopular = {true}
                  sx={{
                    height: "100%",
                    maxWidth: 460,
                    mx: "auto",
                  }}
                />
              </Grid>
              <Grid xs={12} md={4}>
                <PricingPlan
                  companyId={companyId}
                  currentPlan={currentPlan}
                  cta={getCTAText("leader", companyId, currentPlan)}
                  currency="$"
                  description="To familiarize yourself with our tools."
                  features={[
                    {
                      value: "Everything in Enterprise PRO",
                      isBold: true,
                    },
                    {
                      value: "Up to 30 Group Entities",
                    },
                    {
                      value: "Up to 150 of your Team",
                    },
                    {
                      value: "CbCR",
                    },
                    {
                      value: "Transfer Pricing Local Files",
                    },
                    {
                      value: "Year-End Adjustments",
                    },
                    {
                      value: "Full Implementations",
                    },
                    {
                      value: "Priority Customer Support",
                    },
                  ]}
                  icon={<PricingPlanIcon name="business" />}
                  name="Leader PRO"
                  price="9,900"
                  period="year"
                  plan="leader"
                  isPopular = {false}
                  sx={{
                    height: "100%",
                    maxWidth: 460,
                    mx: "auto",
                  }}
                />
              </Grid>
            </Grid>
            {(!companyId && !currentPlan) && (
              <Box sx={{ mt: 4 }}>
                <Typography
                  align="center"
                  color="text.secondary"
                  component="p"
                  variant="caption"
                >
                  No credit card required
                </Typography>
              </Box>
            )}
          </Container>
        </Box>
        <Divider />
        {/* <PricingFaqs /> */}
      </Box>
    </>
  );
};

export default Page;
