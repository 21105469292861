import type { FC } from "react";
import { useEffect, useState } from "react";
import LinkExternal01Icon from "@untitled-ui/icons-react/build/esm/LinkExternal01";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

interface Feature {
  id: string;
  title: string;
  description: string;
  imageDark: string;
  imageLight: string;
}

const features: Feature[] = [
  {
    id: "see-around-corners",
    title: "See around corners",
    description:
      "Gain insight with an intelligent command center and simplify complex tasks in one place. Access supporting evidence and workpapers for informed decision-making.",
    imageDark: "/assets/see-around-corners.webm",
    imageLight: "/assets/see-around-corners.webm",
  },
  {
    id: "leverage-automation",
    title: "Leverage automation",
    description:
      "Save time and overheads with AI-assisted tools to close inefficiency gaps. Optimize workflows with automated market insights and industry benchmarks. Harnessing automation allows your team to focus on value-added work.",
    imageDark: "/assets/leverage-automation.webm",
    imageLight: "/assets/leverage-automation.webm",
  },
  {
    id: "mitigate-risk",
    title: "Mitigate risk",
    description:
      "Reduce compliance risks with real-time monitoring across jurisdictions. Minimize penalties and streamline workflows for finance, risk, and tax teams.",
    imageDark: "/assets/mitigate-risk.webm",
    imageLight: "/assets/mitigate-risk.webm",
  },
  {
    id: "observability",
    title: "Observability",
    description:
      "Build compliant processes with observability for secure audit trails. Support remote workers while preserving transparency. Ultimately, visibility is defensibility.",
    imageDark: "/assets/observability.webm",
    imageLight: "/assets/observability.webm",
  },
  {
    id: "resilience-agility",
    title: "Build resilience and agility",
    description:
      "Develop the ability to adapt quickly to changing market conditions, business needs, and regulatory change.",
    imageDark: "/assets/build-resilience-agility.webm",
    imageLight: "/assets/build-resilience-agility.webm",
  },
  {
    id: "cultivate-accountability",
    title: "Cultivate accountability",
    description:
      "Drive accountability with precise access controls and approval flows. Assign tasks efficiently and collaborate seamlessly while minimizing reliance on key individuals.",
    imageDark: "/assets/cultivate-accountability.webm",
    imageLight: "/assets/cultivate-accountability.webm",
  },
];

export const HomeFeatures: FC = () => {
  const [activeFeature, setActiveFeature] = useState<number>(0);
  // const theme = useTheme();
  // const feature = features[activeFeature];
  // const videoSrc =
  //   theme.palette.mode === "dark" ? feature?.imageDark : feature?.imageLight;

  return (
    <Box
      sx={{
        backgroundColor: "neutral.800",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        backgroundImage: 'url("/assets/gradient-bg.svg")',
        color: "common.white",
        py: "120px",
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={2} sx={{ mb: 8 }}>
          <Typography align="center" color="inherit" variant="h3">
            Transform your Governance, Risk, and Compliance
          </Typography>
          <Typography align="center" color="inherit" variant="subtitle2">
            You will save hundreds of hours of manual work and up to 90% of
            compliance costs.
          </Typography>
        </Stack>
        <Grid alignItems="center" container spacing={3}>
          <Grid xs={12} md={6}>
            <Stack spacing={1}>
              {features.map((feature, index) => {
                const isActive = activeFeature === index;

                return (
                  <Box
                    key={feature.id}
                    onClick={() => setActiveFeature(index)}
                    sx={{
                      borderRadius: 2.5,
                      color: "neutral.400",
                      cursor: "pointer",
                      p: 3,
                      transition: (theme) =>
                        theme.transitions.create(
                          ["background-color, box-shadow", "color"],
                          {
                            easing: theme.transitions.easing.easeOut,
                            duration: theme.transitions.duration.enteringScreen,
                          }
                        ),
                      ...(isActive && {
                        backgroundColor: "primary.alpha12",
                        boxShadow: (theme) =>
                          `${theme.palette.primary.main} 0 0 0 1px`,
                        color: "common.white",
                      }),
                      "&:hover": {
                        ...(!isActive && {
                          backgroundColor: "primary.alpha4",
                          boxShadow: (theme) =>
                            `${theme.palette.primary.main} 0 0 0 1px`,
                          color: "common.white",
                        }),
                      },
                    }}
                  >
                    <Typography color="inherit" sx={{ mb: 1 }} variant="h6">
                      {feature.title}
                    </Typography>
                    <Typography color="inherit" variant="body2">
                      {feature.description}
                    </Typography>
                    {/* {feature.id === 'figma' && (
                      <Box sx={{ mt: 3 }}>
                        <Button
                          color="success"
                          component="a"
                          endIcon={(
                            <SvgIcon fontSize="small">
                              <LinkExternal01Icon />
                            </SvgIcon>
                          )}
                          href="https://www.figma.com/file/xrx6uDljzsWuDZiuz3ITCp/Devias-Kit-Pro-UI-6.0-Master"
                          size="small"
                          target="_blank"
                          variant="contained"
                        >
                          Preview in Figma
                        </Button>
                      </Box>
                    )} */}
                  </Box>
                );
              })}
            </Stack>
          </Grid>
          <Grid xs={12} md={6}>
            <Box
              sx={{
                "& img": {
                  width: "100%",
                },
              }}
            >
              <video
                key={features[activeFeature].imageLight}
                autoPlay
                loop
                muted
                style={{ width: "100%" }}
              >
                <source
                  src={features[activeFeature].imageLight}
                  type="video/webm"
                />
                Your browser does not support the video tag.
              </video>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
