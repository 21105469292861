import type { FC } from 'react';

export const Logo: FC = () => {

  return (
    <svg version="1.1" viewBox="0.0 0.0 400.0 500.0" fill="none" stroke="none" stroke-linecap="square"
    stroke-miterlimit="10" xmlns="http://www.w3.org/2000/svg">
    <clipPath id="g25bae5a3591_0_6.0">
        <path d="m0 0l400.0 0l0 500.0l-400.0 0l0 -500.0z" clip-rule="nonzero" />
    </clipPath>
    <g clip-path="url(#g25bae5a3591_0_6.0)">
        <path fill="#000000" fill-opacity="0.0" d="m0 0l400.0 0l0 500.0l-400.0 0z"
            fill-rule="evenodd" />
        <path fill="#000000" fill-opacity="0.0"
            d="m-10.474541 -73.19685l400.0 0l0 646.3937l-400.0 0z" fill-rule="evenodd" />
        <path fill="#1155cc"
            d="m177.02547 48.78124l50.515625 0l0 47.124996q80.20311 4.953125 122.781235 46.875q42.578125 41.921875 42.578125 104.171875q0 69.781265 -46.09375 110.156265q-46.09375 40.359375 -119.26561 42.96875l0 55.71875l-50.515625 0l0 -55.71875q-75.26563 -2.875 -120.31251 -44.40625q-45.046875 -41.53125 -45.046875 -106.375015q0 -42.453125 19.53125 -76.828125q19.53125 -34.375 55.203125 -54.15625q35.671875 -19.796875 90.62501 -22.40625l0 -47.124996zm0 90.875q-53.640633 1.5625 -83.20313 29.4375q-29.5625 27.859375 -29.5625 79.9375q0 52.60939 32.296875 79.95314q32.296883 27.34375 80.46876 28.125l0 -217.45314zm50.515625 217.45314q53.39061 -1.5625 83.593735 -29.953125q30.203125 -28.390625 30.203125 -78.906265q0 -47.921875 -28.90625 -78.25q-28.90625 -30.34375 -84.89061 -30.34375l0 217.45314z"
            fill-rule="nonzero" />
    </g>
</svg>
  );
};
