import type { FC } from 'react';
import { useState } from 'react';
import ChevronDownIcon from '@untitled-ui/icons-react/build/esm/ChevronDown';
import ChevronRightIcon from '@untitled-ui/icons-react/build/esm/ChevronRight';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';

interface FaqType {
  question: string;
  answer: string;
}

const faqs: FaqType[] = [
  {
    question: 'Can I try out the platform to see how it works?',
    answer: 'Yes, we offer a 7-day free trial without a credit card needed. You can take advantage of this offer through our normal "Instant Access" signup process.'
  },
  {
    question: 'How many companies can I use with each Operating PRO account?',
    answer: 'For a Business PRO account, a business group can have up to 3 connected entities. For an Enterprise PRO account, you can have up to 10 connected entities. For a Leader PRO account, you can have up to 30 connected entities. If you require more connected entities, please contact us for custom pricing. Please note, all entities must be part of the same business group of related entities (through either shared ownership, control, or influence).'
  },
  {
    question: 'Do you offer a monthly subscription?',
    answer: "No, we don't offer monthly subscriptions. Our focus is on providing long-term value through compliance frameworks, which requires an annual commitment from you. Compliance isn't month-to-month. We do however offer a 7-day free trial, without a credit card to try us out."
  },
  {
    question: 'Which countries does your software support?',
    answer: 'Our software services are available to entities in 190 countries. However, we do not offer services to entities or individuals based in Cuba, Iran, North Korea, Sudan, or Syria.'
  }
];

interface FaqProps {
  answer: string;
  question: string;
}

const Faq: FC<FaqProps> = (props) => {
  const { answer, question } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <Stack
      onClick={() => setIsExpanded((prevState) => !prevState)}
      spacing={2}
      sx={{ cursor: 'pointer' }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        <Typography variant="subtitle1">
          {question}
        </Typography>
        <SvgIcon>
          {isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
        </SvgIcon>
      </Stack>
      <Collapse in={isExpanded}>
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {answer}
        </Typography>
      </Collapse>
    </Stack>
  );
};

export const HomeFaqs: FC = () => {
  return (
    <Box sx={{ py: '120px' }}>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={4}
        >
          <Grid
            xs={12}
            md={6}
          >
            <Stack spacing={2}>
              <Typography variant="h3">
                Everything you need to know
              </Typography>
              <Typography
                color="text.secondary"
                variant="subtitle2"
              >
                Frequently asked questions
              </Typography>
            </Stack>
          </Grid>
          <Grid
            xs={12}
            md={6}
          >
            <Stack spacing={4}>
              {faqs.map((faq, index) => (
                <Faq
                  key={index}
                  {...faq}
                />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
